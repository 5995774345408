import PricingDefaultCard from 'components/pages/pricing/pricing-default/PricingDefaultCard';
import React, { useContext, useEffect, useState } from 'react'
import { Card, CardGroup, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getSubscriptionsActive } from 'services/subscriptionService';
import AppContext from 'context/Context';
import { SubscriptionHeader } from './subscriptionHeader';

export const Subscription = () => {
    const[data, setData] = useState({ subscriptionName: '-', amount: 0, subscriptions: [], isActive: false });
    const[isLoading, setIsLoading] = useState(false);
    const[target, setTarget] = useState('subscription');

    const {
        setBreadcrumbs,
        updateCredits
    } = useContext(AppContext);

    useEffect(() => {
        setBreadcrumbs('Subscription');
        getSubscription();
    }, []);

    const getSubscription = async () => {
        try {
            setIsLoading(true);
            var { data } = await getSubscriptionsActive();
            setData(data);
            updateCredits(data.amount);
        } catch (error) {
            toast.error(`Error loading suscriptions`, {
                theme: 'colored',
                className: 'bg-danger'
            });
        } finally {
            setIsLoading(false);
        }
    }

    const onChangeTarget = () => {
        setTarget(target === 'subscription' ? 'recharge' : 'subscription');
    }

    return (
        <>
            {
                isLoading ? 
                    (
                        <Row className="g-0">
                            <Col xs={12} className="py-4">
                                <Spinner className="position-absolute start-50" animation="grow" />
                            </Col>
                        </Row>
                    )
                : 
                    (
                        <>
                            <SubscriptionHeader currentSubscription={data} getSubscription={getSubscription} onChangeTarget={onChangeTarget} type={target} />
                            <Card className="mb-3">
                                <Card.Body>
                                    <Row className="g-0">
                                        <CardGroup className='mt-1'>
                                            {
                                                target === 'subscription' ?
                                                (data.subscriptions && data.subscriptions.map((p, i) => (<PricingDefaultCard type={target} key={i} onReloadSubscription={getSubscription} pricing={{
                                                    title: p.chargeName,
                                                    subTitle: p.description,
                                                    price: p.price,
                                                    buttonText: ((data.subscriptionName === p.chargeName && data.state === 'active') ?'Active Subscription':'Subscribe') ,
                                                    features: p.fields.map((field, f) => ({
                                                        id: f,
                                                        title: field
                                                    })),
                                                    isDisable: (data.state === 'pending' || (data.subscriptionName === p.chargeName && data.state === 'active'))
                                                }} />)))
                                                :
                                                (data.packages && data.packages.map((p, i) => (<PricingDefaultCard type={target} key={i} onReloadSubscription={getSubscription} pricing={{
                                                    title: p.packageName,
                                                    subTitle: 'Express recharge and keep sending messages.',
                                                    price: p.price,
                                                    buttonText: 'Purchase',
                                                    features: [{id: 1, title: `${p.credits} credits to use.`},
                                                               {id: 2, title: `No expiry date.`}],
                                                }} />)))
                                            }
                                        </CardGroup>
                                    </Row>  
                                </Card.Body>
                            </Card>
                        </>
                    )
            }
        </>
    )
}
