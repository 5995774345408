import { FaFileArchive, FaShieldAlt } from "react-icons/fa";

export const subscriptionRoutes = {
  label: 'Subscription',
  labelDisable: true,
  active: true,
  children: [
    {
      name: 'Manage Subscription',
      icon: 'tags',
      active: true,
      to: '/subscription'
    }
  ]
};

export const gdprRoutes = {
  label: 'GDPR',
  labelDisable: true,
  active: true,
  children: [
    {
      name: 'GDPR',
      iconAux: <FaFileArchive />,
      active: true,
      to: '/gdpr'
    }
  ]
};

export const privacyRoutes = {
  label: 'privacy',
  labelDisable: true,
  active: true,
  children: [
    {
      name: 'Privacy Policy',
      iconAux: <FaShieldAlt />,
      target: '_blank',
      active: true,
      to: 'https://www.flowstates.net/other/privacy'
    }
  ]
};

export default [
  subscriptionRoutes,
  gdprRoutes,
  privacyRoutes
];
